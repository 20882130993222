import {
  DkodaPricingConfiguration,
  ERC20,
  NewPoolRequestBody,
} from "@/types/types";
import {BigNumber} from "ethers";
import {parseEther} from "ethers/lib/utils";
import {Address, zeroAddress} from "viem";
import {Token} from "@/components/Perps/types";
import {AddressZero} from "@ethersproject/constants";
import {blast, blastSepolia} from "@/util/additionalChains";
import {WETH_ADDRESS} from "@/util/chainConstants";
import {mainnet} from "wagmi/chains";

// Number of seconds in a day
export const DAY_SECONDS = 86400;

export const DEFAULT_SLIPPAGE = 100; // 1%
export const WARNING_SLIPPAGE = 300; // 1%
export const MAX_SLIPPAGE = 1000; // 10%

export const DEFAULT_PRICING_CONFIG: DkodaPricingConfiguration = {
  poolAddress: zeroAddress,
  premiumMultiplierPercent: 100,
  minStrike: parseEther('0'),
  maxStrike: parseEther('0'),
  minDuration: BigNumber.from(DAY_SECONDS), // 1 day
  maxDuration: BigNumber.from(2 * 30 * DAY_SECONDS), // 2 Months
  callEnabled: true,
  putEnabled: true,
  blockNumber: 0
}

// Default Goerli
export const CHAIN_ID: number = process.env.NEXT_PUBLIC_CHAIN_ID ? Number.parseInt(process.env.NEXT_PUBLIC_CHAIN_ID) : 5;

export const isBlast = CHAIN_ID === blast.id || CHAIN_ID === blastSepolia.id;

export const DISCORD_LINK = "https://discord.gg/UYJdWGP7Gj";
export const TWITTER_LINK = "https://twitter.com/wasabi_protocol";
export const GITBOOK_LINK = "https://docs.wasabi.xyz/";
export const MIRROR_LINK = "https://mirror.xyz/wasabixyz.eth";
export const WASABI_PASS_LINK = "https://opensea.io/collection/wasabi-pass";
export const DUNE_LINK = "https://dune.com/icecreamguy/wasabi";
export const PERP_ANALYTICS_LINK = isBlast ? "https://dune.com/icecreamguy/wasabi-perps-blast" : "https://dune.com/icecreamguy/wasabi-perps";

// Guide links
export const GETTING_STARTED_LINK = "https://docs.wasabi.xyz/_/overview/what-is-wasabi";
export const ARBITRAGE_GUIDE_LINK = "https://docs.wasabi.xyz/wasabi/guides/arbitrage";

export const PERP_TESTNET_LINK = "https://test.wasabi.xyz";
export const PERP_MAINNET_LINK = "https://perps.wasabi.xyz";
export const OPTIONS_TESTNET_LINK = "https://goerli.wasabi.xyz/collections";
export const OPTIONS_MAINNET_LINK = "https://app.wasabi.xyz/collections";
export const BLAST_MAINNET_LINK = "https://blast.wasabi.xyz/";


// Contract Addresses
export const WASABI_POOL_FACTORY = process.env.NEXT_PUBLIC_WASABI_POOL_FACTORY || zeroAddress;
export const WASABI_OPTION = (process.env.NEXT_PUBLIC_WASABI_OPTION || zeroAddress) as Address;
export const WASABI_ADMIN = process.env.NEXT_PUBLIC_WASABI_ADMIN || zeroAddress;
export const WASABI_CONDUIT = (process.env.NEXT_PUBLIC_WASABI_CONDUIT || zeroAddress) as Address;
export const WASABI_BNPL = (process.env.NEXT_PUBLIC_WASABI_BNPL || "0xead3dd83ed1e107e02e1d0a307d4f1ba8a2af12d") as Address;
export const PRICING_CONFIG_VALIDATOR = (process.env.NEXT_PUBLIC_PRICING_CONFIG_VALIDATOR || zeroAddress) as Address;
export const WASABI_BNPL_2 = (process.env.NEXT_PUBLIC_WASABI_BNPL_2 || "0xdaf28ddb794373f3124ee2b0b69c2aaf478aeb89") as Address;
export const OPTION_TRANSFERER = "0x082481d2223a1372d975edc9ccfea3d77b1b595f";
export const PERMIT_2 = "0x000000000022d473030f116ddee9f6b43ac78ba3";
export const UNIVERSAL_ROUTER = "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD";
export const FRACTIONAL_NFT_ROUTER =  "0xdf2fc74879460839b213ad2768d1592ff810d984";

export const PERPS_VIEW = (process.env.NEXT_PUBLIC_PERPS_VIEW || 'false') === "true";

export function isBNPL(poolAddress: string): boolean {
  return poolAddress.toLowerCase() === WASABI_BNPL.toLowerCase() || poolAddress.toLowerCase() === WASABI_BNPL_2.toLowerCase();
}

export const DEMO_ETH = (process.env.NEXT_PUBLIC_DEMO_ETH || zeroAddress) as Address;
export const WETH = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";

export const WASABI_ARBITRAGE_CONTRACT = process.env.NEXT_PUBLIC_WASABI_ARBITRAGE_CONTRACT || zeroAddress;
export const WASABI_ARBITRAGE_CONTRACT_2 = process.env.NEXT_PUBLIC_WASABI_ARBITRAGE_CONTRACT_2 || "0x8b5cd3DdA28bD2Ab32cE012ebFa91aF5c3bbd8f3";
export const NFT_AMM = process.env.NEXT_PUBLIC_NFT_AMM || zeroAddress;

export const WASABI_PASS_ADDRESS: Address = "0x2d850f76c671aa2e1c1892a0644c115eb254d165";

// API Keys
export const ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY || 'PZj_VqXVikfLaVHmmD7mC23RDecOKL_H';
export const WALLET_CONNECT_PROJECT_ID = "e998000bbe395ffa0578ea8d20551bc3";

export const ALLOWED_TOKENS: ERC20[] = CHAIN_ID === 1 ? [
  {
    name: "Wrapped ETH",
    symbol: "WETH",
    address: WETH,
    icon: "/static/weth.png",
  },
] : [
  {
    name: "Demo ETH",
    symbol: "DETH",
    address: DEMO_ETH,
    icon: undefined,
  }
] as ERC20[];

export function getBanner(optionId: string): string {
  return `https://wasabi-public.s3.amazonaws.com/banners/options/${CHAIN_ID === 1 ? "mainnet" : "goerli"}/${optionId}.png`;
}

export const DEFAULT_POOL: NewPoolRequestBody = {
  pricingConfiguration: DEFAULT_PRICING_CONFIG,
  initialTokenIds: [],
  initialEthAmount: BigNumber.from(0),
  nftAddress: zeroAddress,
  tokenAddress: CHAIN_ID === 1 ? "" : DEMO_ETH,
  admin: WASABI_ADMIN
};

export const TERMS_OF_USE_LINK = "https://www.wasabi.xyz/legal/terms-and-conditions";
export const PRIVACY_POLICY_LINK = "https://www.wasabi.xyz/legal/privacy-policy";
export const RESPONSIBLE_DISCLOSURE_LINK = "https://www.wasabi.xyz/legal/responsible-disclosure-policy";

export const TEAM_WHITELIST = [
  "0x129320410d1F827597Befcb01Dc7a037c7fbA6d5", // Testnet Deployer
  "0x5C629f8C0B5368F523C85bFe79d2A8EFB64fB0c8", // Wasabi Deployer
  "0xC6834A309e38E24673bcf240B64C0e2d93faF45a", // Hasan
  "0x735d06aD1F537001dfCeda4745541dCa048ee088", // Eren
  "0x6157f99a85cC33CA489b0Da7058Bf87d17e24A14", // Mason
  "0xDB4FA66fcBaE2Ce72d365F0Ce2ea881a16a9621B", // Mason2
  "0x7B38f14A5076f2A996a926fE7d041305F2280c86", // MAYC
  "0xcB06bEDe7cB0a4b333581B6BdcD05f7cc737b9cC", // Matas
  "0x1AA27526F23063e1dD023e883e086aE0829556C5", // Altug
  "0xBD4c4049bF7B42889D343384743a808F9D6a1f45", // Alex
  "0xF427EDFf4e614d5926CfBeD455d386238EAd2c17", // Bill
]

export const isEthMainnet = CHAIN_ID === 1;
export const isMainnet = CHAIN_ID === 1 || CHAIN_ID === blast.id;

export const defaultToken =
  CHAIN_ID === blastSepolia.id
    ? "BLAST"
    : CHAIN_ID === blast.id
      ? "USDB"
      : CHAIN_ID === mainnet.id
        ? "Mog"
        : "WBTC";

export const BLAST_WETH = "0x4300000000000000000000000000000000000004";
export const BLAST_TOKEN = "0xb1a5700fA2358173Fe465e6eA4Ff52E36e88E2ad";

export const getBaseUrl = (chainId: number = CHAIN_ID) => {
  if (!PERPS_VIEW) {
    return "https://app.wasabi.xyz";
  }

  if (chainId === 1) {
    return "https://perps.wasabi.xyz";
  } else if (chainId === blast.id) {
    return "https://blast.wasabi.xyz";
  } else if (chainId === blastSepolia.id) {
    return "https://test.wasabi.xyz";
  } else {
    return "https://goerli.wasabi.xyz";
  }
}

export const getOgImage = () => {
  if (isBlast) {
    return `${getBaseUrl()}/static/og_blast.png`
  }
  return `${getBaseUrl()}/static/og.png`
}
