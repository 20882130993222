import React, {MouseEventHandler} from "react";
import classNames from "classnames";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import {twMerge} from "tailwind-merge";

export enum ButtonType {
  PRIMARY, SECONDARY, SELECTED, NEUTRAL, YELLOW, NEUTRAL_FILL, DESTRUCTIVE
}

export interface ButtonProps extends React.PropsWithChildren {
  id?: string
  onClick?: MouseEventHandler;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  buttonType?: ButtonType
}

export const Button: React.FC<ButtonProps> = ({
  children,
  id,
  onClick,
  disabled = false,
  loading = false,
  className = '',
  buttonType = ButtonType.PRIMARY
}) => {
  return (
    <button
      id={id}
      onClick={(e) => onClick && onClick(e)}
      disabled={disabled || loading}
      className={twMerge(classNames("wasabi-button", {
        "wasabi-button-primary": buttonType === undefined || buttonType === ButtonType.PRIMARY,
        "wasabi-button-secondary": buttonType === ButtonType.SECONDARY,
        "wasabi-button-selected": buttonType === ButtonType.SELECTED,
        "wasabi-button-neutral": buttonType === ButtonType.NEUTRAL,
        "wasabi-button-yellow": buttonType === ButtonType.YELLOW,
        "wasabi-button-destructive": buttonType === ButtonType.DESTRUCTIVE,
        "wasabi-button-neutral-fill": buttonType === ButtonType.NEUTRAL_FILL
      }), className || '')}
    >
      {loading && <LoadingSpinner size={4} />}
      {children}
    </button>
  );
};
